import React from "react"
import { useSiteMetadata } from "../Hooks/use-site-metadata"

export const SEO = ({ title, description=undefined, author=undefined, keywords=undefined, pathname, children=undefined }) => {
  const { 
    title: defaultTitle, 
    description: defaultDescription, 
    author: defaultAuthor,
    keywords: defaultKeywords,
    siteUrl,
    image,
    twitterUsername,
    twitterUrl,
    linkedinUrl,
    githubUrl,
    gitlabUrl
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    author: author || defaultAuthor,
    keywords: keywords || defaultKeywords,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername: twitterUsername,
    twitterUrl: twitterUrl,
    linkedinUrl: linkedinUrl,
    githubUrl: githubUrl,
    gitlabUrl: gitlabUrl,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="og:image" content={seo.image} />
      <meta name="og:keywords" content={seo.keywords} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="twitter:url" content={seo.twitterUrl} />
      <meta name="linkedin:url" content={seo.linkedinUrl} />
      <meta name="github:url" content={seo.githubUrl} />
      <meta name="gitlab:url" content={seo.gitlabUrl} />      
      {children}
    </>
  )
}
