import React from "react"
import { styled } from "../../../stitches.config"

import { Link } from "../../components"

const Container = styled("div", {
  length: 0,
  color: "$slate12",
  fontFamily: "$display",
  fontSize: "$4xl",
  fontWeight: 600,
  lineHeight: "1.194444",
  maxWidth: 520,
})

export const SayHi: React.FC = () => (
  <Container>
    Send me a message,{" "}
    <Link
      aria-label="Send an E-Mail to hello@zatura.me"
      href="mailto:hello@zatura.me"
      underlined
    >
      say hi!
    </Link>
  </Container>
)
