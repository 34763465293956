import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import { styled } from "../../../stitches.config"
import { SEO } from "../../components/Seo/Seo"

const Separator = styled("span", {
  length: 0,
  color: "$slate11",
  display: "inline-block",
  fontFamily: "$default",
  fontSize: "$base",
  margin: "0 $2",

  variants: {
    size: {
      default: {
        margin: "0 $1",
        fontSize: "$base",
      },
      large: {
        margin: "0 $2",
        fontSize: "$lg",
      },
    },
  },

  "&::before": {
    content: "' '",
  },
})

const MenuItem = styled("span", {
  length: 0,
  fontFamily: "$default",
  fontSize: "$sm",
  fontWeight: 600,

  variants: {
    size: {
      default: {
        fontSize: "$base",
      },
      lg: {
        fontSize: "$lg",
      },
    },
  },
})

const Container = styled("nav", {
  length: 0,
  alignItems: "center",
  color: "$slate11",
  display: "flex",

  variants: {
    spacing: {
      default: {
        marginBottom: "$16",
      },
      lg: {
        marginBottom: "$32",
      },
    },
  },
})

const Navigation = () => {
  const data = useStaticQuery(graphql`
    query {
      resume: file(relativePath: { eq: "files/matheus-tura-resume.pdf" }) {
        publicURL
      }
    }
  `)

  return (
    <Container spacing={{ "@xs": "default", "@sm": "lg" }}>
      <Link activeStyle={{ color: "#FFF" }} to="/">
        <MenuItem size={{ "@xs": "default", "@sm": "lg" }}>
          Home
        </MenuItem>
      </Link>

      <Separator size={{ initial: "default", "@xs": "large" }} />
      <Link activeStyle={{ color: "#FFF" }} to="/about">
        <MenuItem size={{ "@xs": "default", "@sm": "lg" }}>
          About
        </MenuItem>
      </Link>

      <Separator size={{ initial: "default", "@xs": "large" }} />
      <Link activeStyle={{ color: "#FFF" }} to="/work">
        <MenuItem size={{ "@xs": "default", "@sm": "lg" }}>
          Work
        </MenuItem>
      </Link>

      <Separator size={{ initial: "default", "@xs": "large" }} />      
      <a href={data.resume.publicURL} aria-label="Resume">
        <MenuItem size={{ "@xs": "default", "@sm": "lg" }}>
          Resume
        </MenuItem>
      </a>
      <Separator size={{ initial: "default", "@xs": "large" }} />
      <Link activeStyle={{ color: "#FFF" }} to="/blog">
        <MenuItem size={{ "@xs": "default", "@sm": "lg" }}>
          Blog
        </MenuItem>
      </Link>
      <Separator size={{ initial: "default", "@xs": "large" }} />
      <Link activeStyle={{ color: "#FFF" }} to="/contact">
        <MenuItem size={{ "@xs": "default", "@sm": "lg" }}>
          Contact
        </MenuItem>
      </Link>
    </Container>
  )
}

export { Navigation }

export const Head = () => (
  <SEO title="navigation"/>
)
