import React from "react"
import { styled } from "../../stitches.config"
import { PageLayout } from "../layouts"
import { List, ListItem, Paragraph, Meta, Heading } from "../components"
import { SEO } from "../components/Seo/Seo"


const NotFound = () => (
  <PageLayout>
    <Meta title="404: Not found" />
    <Container>
      <Heading level="heading1">
        That's the 404 page<br/><br/>
        Looks like you are looking for a page that does not exist.<br/><br/>
      </Heading>
      <Paragraph>
        If you came here from a Google Search, feel free to go back and try a
        different search term. Otherwise, here are some things that you might
        have been looking for:
      </Paragraph>
      <Paragraph>
        <List>
          <ListItem link="/">Homepage</ListItem>
          <ListItem link="/about">Information about myself</ListItem>
          <ListItem link="/work">Relevant works</ListItem>
        </List>
      </Paragraph>
    </Container>
  </PageLayout>
)

const Container = styled("div", {
  maxWidth: 640,
  length: 0
})

export default NotFound

export const Head = () => (
  <SEO title="404" pathname="/404"/>
)
